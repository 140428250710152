import { PaymentType } from '../helpers/constants';
import { updateMe } from './session';
import * as Constant from './constants';
import * as Api from '../apis';

export const postMembershipOrder = () => {
  return (dispatch, getState) => {
    dispatch({ type: Constant.MEMBERSHIP_ORDER_POST_REQUEST });

    const { orderInfo, session } = getState();
    const { plan, payment, name, promotionId, buyFor } = orderInfo;

    const _name = name ? name.split(' ') : [];
    const firstName = _name[0];
    const lastName = _name[1];

    const input = {
      card_type: plan.cardtype.id,
      promotion_id: promotionId,
      purchase_type: buyFor,
    };

    if (payment && typeof payment === 'object') {
      input.checkout_method = {
        method: PaymentType.DEFAULT_CREDIT_CARD,
      };
    } else {
      input.checkout_method = {
        method: payment,
      };
    }

    return Promise.resolve()
      .then(() => {
        if (!payment) return Promise.reject('กรุณาเลือกช่องทางการชำระเงิน');
        if (!session.user && !session.line && !session.facebook) {
          return Promise.reject('กรุณาเข้าสู่ระบบ');
        }
        return !session.user.first_name
          ? dispatch(updateMe(firstName, lastName))
          : Promise.resolve();
      })
      .then(() => {
        return Api.postMembershipOrder(input);
      })
      .then(payload => {
        dispatch({ type: Constant.MEMBERSHIP_ORDER_POST_SUCCESS, payload });
        return payload;
      })
      .catch(message => {
        dispatch({ type: Constant.MEMBERSHIP_ORDER_POST_FAILED, message });
        return Promise.reject(message);
      });
  };
};

export const postMembershipOrderSummary = () => {
  return (dispatch, getState) => {
    dispatch({ type: Constant.MEMBERSHIP_ORDER_SUMMARY_POST_REQUEST });

    const { orderInfo, session } = getState();
    const { plan, payment, name, promotionId } = orderInfo;

    const _name = name ? name.split(' ') : [];
    const firstName = _name[0];
    const lastName = _name[1];

    const input = {
      card_type: plan.cardtype.id,
      promotion_id: promotionId,
    };

    return Api.postMembershipOrderSummary(input)
      .then(payload => {
        dispatch({
          type: Constant.MEMBERSHIP_ORDER_SUMMARY_POST_SUCCESS,
          payload,
        });
        return payload;
      })
      .catch(message => {
        dispatch({
          type: Constant.MEMBERSHIP_ORDER_SUMMARY_POST_FAILED,
          message,
        });
        return Promise.reject(message);
      });
  };
};

export const getMembershipPlans = () => {
  return dispatch => {
    dispatch({ type: Constant.MEMBERSHIP_PLAN_GET_REQUEST });
    return Api.getMembershipPlans()
      .then(payload => {
        dispatch({ type: Constant.MEMBERSHIP_PLAN_GET_SUCCESS, payload });
        return payload;
      })
      .catch(message => {
        dispatch({ type: Constant.MEMBERSHIP_PLAN_GET_FAILED, message });
      });
  };
};

export const getMembershipCardList = (partners=false) => {
  return dispatch => {
    dispatch({ type: Constant.MEMBERSHIP_CARDS_GET_REQUEST });
    return Api.getMembershipCardList(partners)
      .then(payload => {
        dispatch({ type: Constant.MEMBERSHIP_CARDS_GET_SUCCESS, payload });
        if (payload) {
          dispatch({ type: Constant.SET_CURRENT_MEMBERSHIP_CARD, payload: payload.results[0] });
        }
        return payload;
      })
      .catch(message => {
        dispatch({ type: Constant.MEMBERSHIP_CARDS_GET_FAILED, message });
      });
  };
};

export const checkMembershipCardBenefit = (cardId, isAddOnBenefit, benefit) => {
  return dispatch => {
    dispatch({ type: Constant.MEMBERSHIP_CARDS_GET_REQUEST });
    return Api.checkMembershipCardBenefit(cardId, isAddOnBenefit, benefit)
      .then(payload => {
        dispatch({ type: Constant.MEMBERSHIP_CARD_BENEFIT_CHECK_GET_SUCCESS, payload });
        return payload;
      })
      .catch(message => {
        dispatch({ type: Constant.MEMBERSHIP_CARD_BENEFIT_CHECK_GET_FAILED, message });
      });
  };
};

export const setCanUseBenefit = (canUseBenefit) => 
  ({ type: Constant.MEMBERSHIP_CARD_BENEFIT_CHECK_SET, payload: canUseBenefit });

export const setForceMember = value => ({
  type: Constant.SET_FORCE,
  payload: value,
});

export const setCurrentMemberCard = card => ({
  type: Constant.SET_CURRENT_MEMBERSHIP_CARD,
  payload: card,
})