import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';
import { useDispatch } from 'react-redux';
import { getMembershipOrderDetail } from '../../apis';
import { renderAppointmentDateTime } from '../../helpers/date';
import { setForceMember } from '../../actions/membership';
import { getMembershipCardList } from '../../actions/membership';
import { getUrlParams } from '../../helpers/location';
import { Box, TableBody, TableRow, Typography } from '@material-ui/core';
import Layout from '../../components/v2/Layout';
import Table from '../../components/Table';
import Cell from '../../components/Table/cell';
import Button from '../../components/v2/Button';

export default function OrderMembership({ location }) {
  const params = getUrlParams(location.search);
  const dispatch = useDispatch();
  const { id } = params;
  const [ orderDetail, setOrderDetail ] = useState('');
  const [ paymentStatus, setPaymentStatus ] = useState('');
  const [ colorPayment, setColorPayment ] = useState('');
  const [ colorPromotion, setColorPromotion ] = useState('');
  const [ paymentType, setPaymentType ] = useState('');
  const [ redeemCode, setRedeemCode ] = useState('');
  const [ redeemId, setRedeemId ] = useState('');

  useEffect(() => {
    order();
  }, []);

  const order = async () => {
    try {
      dispatch(getMembershipCardList());

      const res = await getMembershipOrderDetail(id);
      setOrderDetail(res);

      res.payment_status === 'paid' ? setPaymentStatus('สำเร็จ') : res.payment_status === 'waiting_paid' ? setPaymentStatus('รอการชำระ') : setPaymentStatus('ไม่สำเร็จ')
      res.payment_status === 'paid' ? setColorPayment('green') : setColorPayment('red')
      res.discount_price > 0 ? setColorPromotion('red') : setColorPromotion('black')
      res.payment_method === 'credit_card' ? setPaymentType('บัตรเครดิต') : res.payment_method === 'cash_on_delivery' ? setPaymentType('ชำระเงินปลายทาง') : setPaymentType('อื่นๆ')
      setRedeemCode(res.membershipredeem[0].redeem[0].redeem_code)
      setRedeemId(res.membershipredeem[0].redeem[0].id)
    } catch (e) {}
  }

  const handleClick = async (path) => {
    if (path === 'member') {
      navigate('/membership');
    } else if (path === 'purchase') {
      dispatch(setForceMember(true));
      await navigate('/membership/pricing');
    } else if (path === 'gift') {
      navigate(`/membership/resultGift?id=${redeemId}`);
    }
  }

  return (
    <Layout
      bg='bg2'
      title={'ประวัติซื้อสมาชิก'}
    >
      <Typography
        variant='h5'
        component='h1'
        color='textPrimary'
        style={{ fontWeight: 600, marginBottom: '15px' }}
      >
        Membership card {orderDetail.purchase_type === 'gift' ? ' (Gift)' : ''}
      </Typography>
      <div style={{ marginBottom: '15px' }}>
        <Table>
          <TableBody>
            <TableRow>
              <Cell style={{ fontSize: '13px' }}><b>ซื้อสมาชิกระดับ {orderDetail.card_type?.name}</b>{orderDetail.purchase_type === 'gift' ? ' ให้เพื่อน' : ''}<b> x1</b></Cell>
              <Cell style={{ fontSize: '13px' }} align='right'><b>{orderDetail.card_type?.price} บาท</b></Cell>
            </TableRow>
          </TableBody>
        </Table>
      </div>
      <Table>
        <TableBody>
        <TableRow>
            <Cell><b>สถานะการชำระเงิน:</b></Cell>
            <Cell align='right' style={{ color: colorPayment}}><b>{paymentStatus}</b></Cell>
          </TableRow>
          <TableRow>
            <Cell><b>ชำระเงินผ่าน:</b></Cell>
            <Cell align='right'>{paymentType}</Cell>
          </TableRow>
          <TableRow>
            <Cell><b>โปรโมชั่น:</b></Cell>
            <Cell align='right' style={{ color: colorPromotion}}>{orderDetail.discount_price > 0 ? '-' : ''}{orderDetail.discount_price} บาท</Cell>
          </TableRow>
          <TableRow>
            <Cell><b>ยอดชำระ:</b></Cell>
            <Cell align='right'>{orderDetail.grand_total_price} บาท</Cell>
          </TableRow>
        </TableBody>
      </Table>
      {
        orderDetail.purchase_type === 'gift' ?
        <Typography
          variant='subtitle2'
          component='p'
          color='textPrimary'
          style={{ marginTop: '25px' }}
        >
          <b>วันที่ซื้อ:</b> {renderAppointmentDateTime(orderDetail.created)}
        </Typography>
        : <Typography
          variant='subtitle2'
          component='p'
          color='textPrimary'
          style={{ marginTop: '25px' }}
        >
          <b>เริ่มใช้งาน:</b> {renderAppointmentDateTime(orderDetail.created)}
        </Typography>
      }
      {
        orderDetail.purchase_type === 'gift' ?
          <Typography
            variant='subtitle2'
            component='p'
            color='textPrimary'
          >
            <b>Redeem code: {redeemCode}</b>
          </Typography>
        : <Typography
          variant='subtitle2'
          component='p'
          color='textPrimary'
        >
          <b>หมดอายุ:</b> {renderAppointmentDateTime(orderDetail.card_order?.membership_card?.expired_date)}
        </Typography>
      }
      <Box display='flex' justifyContent='center' alignItems='center' style={{ marginTop: '25px' }}>
        <Box flex={1}>
          <Button
            fullWidth
            size='small'
            style={{ fontSize: '0.95em' }}
            onClick={() => handleClick('member')}
          >
            {'ไปหน้าสมาชิก'}
          </Button>
        </Box>
        <Box flex={1} marginLeft={1}>
          <Button
            fullWidth
            size='small'
            variant='outlined'
            style={{ fontSize: '0.95em' }}
            onClick={() => handleClick('purchase')}
          >
            {'ซื้ออีกครั้ง'}
          </Button>
        </Box>
      </Box>
      {
        orderDetail.purchase_type === 'gift' && (
          <Box display='flex' justifyContent='center' alignItems='center' style={{ marginTop: '10px' }}>
            <Box flex={1}>
              <Button
                fullWidth
                size='small'
                style={{ fontSize: '0.95em' }}
                onClick={() => handleClick('gift')}
              >
                {'รับ redeem card'}
              </Button>
            </Box>
          </Box>
        )
      }
    </Layout>
  );
}